::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--primary-bg); 
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-bg); 
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #171d2b; 
}

::selection {
  background: var(--accent-clr);
}

::-moz-selection {
  background: var(--accent-clr);
}

body {
  font-family: source-code-pro, monospace;
  background-color: var(--primary-bg);
  color: var(--primary-clr);
  width: 100vw;
  overflow-x: hidden;
}

section {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  position: relative;
  width: 1300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.hero.container {
  height: calc(100vh - 150px);
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.hero.container h1 {
  font-size: 50px;
  margin: 0;
}

.hero.container h2 {
  color: var(--secondary-clr);
  margin: 10px 0;
  font-weight: 500;
}

.dividor {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--accent-clr);
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 25px 25px;
  margin: 25px 0;
}

.project-card {
  background: var(--secondary-bg);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  opacity: 0;
  transform: translateY(100px);
  transition: all 300ms ease;
  gap: 10px;
  min-height: 150px;
  background: radial-gradient(circle at var(--x) var(--y), var(--secondary-bg-effect) 0%, var(--secondary-bg) 100%);
}

.project-card.visible {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease;
}

.project-card h1 {
  margin: 0;
  font-weight: 400;
  font-size: 25px;
  cursor: pointer;
  transition: all 300ms ease;
}

.project-card:hover h1 {
  color: var(--accent-clr);
  transition: all 300ms ease;
}

.project-card p {
  margin: 0;
  color: var(--secondary-clr);
}

.project-links-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid var(--accent-clr);
  background-color: rgba(255, 255, 255, 0);
  color: var(--primary-clr);
  cursor: pointer;
  transition: all 300ms ease;
}

button:hover {
  background-color: var(--accent-clr);
  transition: all 300ms ease;
}

.project-link {
  cursor: pointer;
  transition: all 300ms ease;
  width: 30px;
  height: auto;
}

.project-link:hover {
  opacity: .5;
  transition: all 300ms ease;
}

@media only screen and (max-width: 480px) {

  .hero.container {
    text-align: center;
    align-items: center;
  }

  .hero.container h1 {
    font-size: 35px;
    margin: 0;
  }
  
  .hero.container h2 {
    color: var(--secondary-clr);
    margin: 10px 0;
    font-weight: 500;
  }

  .container {
    padding: 0 10px;
  }

  .projects-grid {
    grid-template-columns: 1fr;
    gap: 10px 10px;
    margin: 25px 0;
  }

  .project-card {
    padding: 15px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 300ms ease;
    gap: 10px;
    min-height: 100px;
  }

}