:root {
    /* dark theme */
    --primary-bg-dark: #0f0f1c;
    --secondary-bg-dark: #111827;
    --secondary-bg-effect-dark: #1e273c;

    --primary-clr-dark: #ffffff;
    --secondary-clr-dark: #e8e8e8bb;
    --accent-clr-dark: #1fa8ed;

    /* light theme */
    --primary-bg-light: #ffffff;
    --secondary-bg-light: #eeeeee;
    --secondary-bg-effect-light: #c3c3c3;

    --primary-clr-light: #000000;
    --secondary-clr-light: #202020bb;
    --accent-clr-light: #1fa8ed;

    /* theme */
    --primary-bg: var(--primary-bg-dark);
    --secondary-bg: var(--secondary-bg-dark);
    --secondary-bg-effect: var(--secondary-bg-effect-dark);

    --primary-clr: var(--primary-clr-dark);
    --secondary-clr: var(--secondary-clr-dark);
    --accent-clr: var(--accent-clr-dark);
}